<template>
  <b-card class="card-custom bg-light-primary gutter-b flex-grow-1">
    <div v-if="!isBusy" class="card-body px-2 py-2">
      <h3 class="font-weight-bolder font-size-h2 mb-1 text-dark-75">
        {{ leave.name }}
      </h3>
      <div class="font-size-sm mb-8">
        {{ leave.balance }}
      </div>
      <!--begin::Info-->
      <div class="d-flex mb-3">
        <span class="text-dark-50 flex-root font-weight-bold"
          >Base Entitlement</span
        >
        <span class="text-dark flex-root font-weight-bold">8.00</span>
      </div>
      <div class="d-flex mb-3">
        <span class="text-dark-50 flex-root font-weight-bold">Unit</span>
        <span class="text-dark flex-root font-weight-bold">{{
          leave.unit.name
        }}</span>
      </div>
      <div class="d-flex mb-3">
        <span class="text-dark-50 flex-root font-weight-bold">Paid Leave?</span>
        <span class="text-dark flex-root font-weight-bold">{{
          leave.paid_leave ? "Yes" : "No"
        }}</span>
      </div>
      <div class="d-flex mb-3">
        <span class="text-dark-50 flex-root font-weight-bold">Description</span>
        <span class="text-dark flex-root font-weight-bold">{{
          leave.remark
        }}</span>
      </div>
      <!--end::Info-->
    </div>
    <b-skeleton-wrapper :loading="isBusy">
      <template #loading>
        <b-skeleton class="mb-5" width="85%"></b-skeleton>

        <b-skeleton width="85%"></b-skeleton>
        <b-skeleton width="55%"></b-skeleton>
        <b-skeleton class="mb-5" width="70%"></b-skeleton>

        <b-skeleton width="85%"></b-skeleton>
        <b-skeleton width="55%"></b-skeleton>
        <b-skeleton width="70%"></b-skeleton>
      </template>
    </b-skeleton-wrapper>
  </b-card>
</template>

<script>
export default {
  name: "CompanyLeaveDetails",
  props: {
    leave: {
      type: Object,
      required: true,
    },
    isBusy: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style scoped></style>
