<template>
  <div>
    <b-form class="form" v-on:submit.prevent="onSubmit">
      <h4 class="font-weight-bolder mb-5"></h4>

      <!-- begin: From Date  -->
      <b-form-group
        label-cols-lg="2"
        label-class="font-weight-bolder"
        label="From Date"
      >
        <b-form-datepicker
          id="from-date-input"
          class="form-control h-auto"
          v-model="$v.form.fromDate.$model"
          :state="validateState('fromDate')"
        >
        </b-form-datepicker>

        <b-form-invalid-feedback v-if="!$v.form.fromDate.required"
          >{{ $t("ALERT.ALERT") }}
        </b-form-invalid-feedback>
      </b-form-group>
      <!-- end: From Date  -->

      <!-- begin: To Date  -->
      <b-form-group
        label-cols-lg="2"
        label-class="font-weight-bolder"
        label="To Date"
      >
        <b-form-datepicker
          id="to-date-input"
          class="form-control h-auto"
          v-model="$v.form.toDate.$model"
          :state="validateState('toDate')"
        >
        </b-form-datepicker>

        <b-form-invalid-feedback v-if="!$v.form.toDate.required"
          >{{ $t("ALERT.ALERT") }}
        </b-form-invalid-feedback>
      </b-form-group>
      <!-- end: To Date  -->

      <!-- begin: remark  -->
      <b-form-group
        label-cols-lg="2"
        label-class="font-weight-bolder"
        label="Reason"
      >
        <b-form-textarea
          id="leave-reason"
          v-model="$v.form.remark.$model"
          :state="validateState('remark')"
        >
        </b-form-textarea>
      </b-form-group>
      <!-- end: remark  -->

      <div class="d-flex justify-content-end border-top pt-3">
        <b-button
          variant="primary"
          ref="employee-general-button"
          class="font-size-h6"
          type="submit"
          v-bind:class="{ 'spinner spinner-light spinner-right': isBusy }"
        >
          {{ $t("EMPLOYEES.DETAILS.SUBMIT") }}
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  name: "IndividualEmployeeLeaveAssignmentForm",
  mixins: [validationMixin],
  data() {
    return {
      form: {
        toDate: "",
        fromDate: "",
        remark: "",
      },
      isBusy: false,
    };
  },
  validations: {
    form: {
      toDate: {
        required,
      },
      fromDate: {
        required,
      },
      remark: {
        required,
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      console.log("Submit");
      console.log("Employee Assignment Form: ", this.form);

      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const form = {
        from_date: this.form.fromDate,
        to_date: this.form.toDate,
        remark: this.form.remark,
      };

      console.log("Created form: ", form);
      this.$emit("onSubmitClicked", form);
    },
  },
};
</script>

<style scoped></style>
